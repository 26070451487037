<template>
  <!-- 编码:lzq -->
  <div>
    <main>
      <!-- 头部横幅 -->
      <div class="banner">
        <div class="banner1">
          <img
            v-lazy="require('../assets/imgs/hardware_deployment/banner.webp')"
            alt=""
          />
        </div>
        <!-- 横幅内容区域 -->
        <div class="banner2">
          <h2 class="title">硬件部署方案</h2>
          <p class="introduce">实现景区智能化，为游客全方位提供优质的服务</p>
        </div>
      </div>
      <p class="title_h2">景区智能硬件部署方案的优势</p>
      <span class="introduce_span">景区智能硬件可以提升旅游品质让游客的体验显著的提升</span>

      <!-- 介绍区域 -->
      <div class="functional_area">
        <div class="chack_box ticket_exit">
          <div>
            <img v-lazy="
                require('../assets/imgs/hardware_deployment/group_up4.webp')
              " />
          </div>
          <span>
            游客通过手机就可以预约景区门票，到了景区也不用取票，直接刷二维码就可快速完成检票，通行效率大大提升
          </span>
          <img
            class="img_1"
            v-lazy="
              require('../assets/imgs/hardware_deployment/group_up01.png')
            "
          />
        </div>
        <div class="chack_box narrate">
          <div>
            <img v-lazy="
                require('../assets/imgs/hardware_deployment/group_up4.2.webp')
              " />
          </div>
          <span>
            游客到了景区，只需要一部手机，就可以获取图、文、音、视频等多种形式的景区解说
          </span>
          <img
            class="img_2"
            v-lazy="require('../assets/imgs/hardware_deployment/group_up2.png')"
          />
        </div>
        <div class="chack_box path">
          <div>
            <img v-lazy="
                require('../assets/imgs/hardware_deployment/group_up.webp')
              " />
          </div>
          <span>
            游客还可以根据自己的需求，获取最符合自己游玩偏好的个性化游玩路线
          </span>
          <img
            class="img_3"
            v-lazy="require('../assets/imgs/hardware_deployment/group_up1.png')"
          />
        </div>
        <div class="chack_box path">
          <div>
            <img v-lazy="
                require('../assets/imgs/hardware_deployment/group_up4.1.webp')
              " />
          </div>
          <span>
            通过无人机了解到游客的实时动态和需求提醒游客注意全，为游客的游玩保驾护航
          </span>
          <img
            class="img_4"
            v-lazy="require('../assets/imgs/hardware_deployment/group_up3.png')"
          />
        </div>
      </div>

      <!-- 优势讲解区域 -->
      <div class="advantage">
        <div class="centers">
          <div class="images">
            <img v-lazy="
                require('../assets/imgs/hardware_deployment/group_up12.webp')
              " />
          </div>
          <div class="character">
            <p>景区智能硬件部署方案的优势</p>
            <span class="spn_1">
              <div></div>
              通过采集、清洗、整合、分析等一系列分析手段，形成的多维度、多类型的旅游数据
            </span>
            <span class="spn_2">
              <div></div>
              通过旅游大数据，管理者可以实时、联动、综合、全面的了解景区经营状况，并通过智慧旅游大数据中心的分析预警，预判景区未来的经营趋势，特别是在应对突发状况时，就可以在第一时间有效地启动应急方案，使得景区管理趋于科学，进一步打造智慧景区
            </span>
          </div>
        </div>
      </div>
      <!-- 部署场景区域 -->
      <div class="scene_area">
        <p class="title">景区智能硬件部署使用场景</p>
        <div class="verify">
          <div class="image_box">
            <img v-lazy="
                require('../assets/imgs/hardware_deployment/group_up5.webp')
              " />
          </div>
          <div class="spans">
            <p>智慧验证</p>
            <span>
              实地购票验票花费较多的时间和人力成本，通过智能验证身份获取游客的购票信息，可以让游客快速通过，解决高峰期游客拥堵的情况，为游客节约了游玩的时间
            </span>
          </div>
        </div>
        <div class="hierophant">
          <div class="spans">
            <p>智慧解说</p>
            <span>
              游客众多的时候，景区不可能做到一人一导游的配置，通过智能解说让游客可通过手机就能了解到景区的特色和历史，提高了游客的游玩体验
            </span>
          </div>
          <div class="image_box">
            <img v-lazy="
                require('../assets/imgs/hardware_deployment/group_up5.2.webp')
              " />
          </div>
        </div>

        <div class="way">
          <div class="image_box">
            <img v-lazy="
                require('../assets/imgs/hardware_deployment/group_up5.1.webp')
              " />
          </div>
          <div class="spans">
            <p>智慧路线</p>
            <span>
              游客初次到景区会对景区不熟悉，不知道如何规划路线，通过智能路线，可根据游客选择的个性爱好为游客规划游玩路线，让游客体验到自己想要的旅游，为游客节约了考虑的时间，让游客不虚此行
            </span>
          </div>
        </div>
        <div class="flay">
          <div class="spans">
            <p>无人机提示</p>
            <span>
              在智能化景区难免有游客不知如何操作景区设施，或者需要人工帮助的时候，可通过无人机提示让游客正确的操作景区设施，为游客提供便捷的方法，也可通过无人机了解到游客的需求，并给与游客帮助
            </span>
          </div>
          <div class="image_box">
            <img v-lazy="
                require('../assets/imgs/hardware_deployment/group_up5.3.webp')
              " />
          </div>
        </div>
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "hardware_deployment",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  mounted() { },
  methods: {},
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.banner {
  height: 600px;
  color: #fff;
  position: relative;
  .banner1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // loading大小
    img[lazy="loading"] {
      width: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  .banner2 {
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 130%);
    z-index: 1;
    .title {
      height: 67px;
      font-size: 48px;
      font-weight: 500;
      line-height: 67px;
      letter-spacing: 3px;
      text-align: left;
      position: absolute;
      left: 361px;
      top: 230px;
    }
    .introduce {
      width: 565px;
      font-size: 24px;
      font-weight: 400;
      line-height: 33px;
      text-align: left;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      position: absolute;
      left: 361px;
      top: 321px;
    }
  }
}
.introduce,
.title {
  animation: sport 0.5s;
  transform: translateY(0px);
}
//设置头部文字动画效果
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

//版心
.title_h2 {
  font-size: 40px;
  font-weight: 500px;
  color: #333;
  letter-spacing: 1px;
  margin-bottom: 24px;
  margin-top: 80px;
}
.introduce_span {
  font-size: 20px;
  font-weight: 400px;
  color: #565656;
  letter-spacing: 1px;
}
.functional_area {
  width: 1200px;
  height: 1298px;
  margin: 60px auto 100px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .chack_box {
    position: relative;
    width: 585px;
    height: 634px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    div {
      width: 100%;
      height: 420px;
      display: flex;
      justify-content: center;
      align-items: center;
      // loading大小
      img[lazy="loading"] {
        width: 150px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        width: 100%;
        height: 100%;
      }
    }
    span {
      text-align: left;
      display: block;
      margin: 34px 36px 0 36px;
      width: 513px;
      height: 108px;
      font-size: 24px;
      font-weight: 400;
      color: #666666;
      line-height: 36px;
      letter-spacing: 1px;
    }
  }
  .path {
    margin-top: 30px;
  }
}
.img_1,
.img_2,
.img_3,
.img_4 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 141px;
  height: 86px;
}
.advantage {
  position: relative;
  height: 565px;
  background: #f6f8fb;
  .centers {
    position: absolute;
    display: flex;
    width: 1200px;
    height: 365px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    .images {
      width: 496px;
      height: 365px;
      display: flex;
      justify-content: center;
      align-items: center;
      // loading大小
      img[lazy="loading"] {
        width: 150px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.character {
  width: 704px;
  height: 365px;
  text-align: left;
  padding-left: 36px;
  padding-right: 36px;
  p {
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 1px;
    margin-top: 52px;
    margin-bottom: 24px;
  }
  .spn_1,
  .spn_2 {
    display: block;
    position: relative;
    margin-left: 25px;
    font-size: 20px;
    font-weight: 400;
    color: #999999;
    div {
      position: absolute;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: #007aff;
      left: -20px;
      top: 6px;
    }
  }
  .spn_1 {
    margin-bottom: 24px;
  }
}
.scene_area {
  width: 1200px;
  margin: auto;
  margin-bottom: 100px;
  .title {
    margin-top: 80px;
    margin-bottom: 60px;
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 1px;
  }
  .verify,
  .hierophant,
  .way,
  .flay {
    width: 1200px;
    height: 324px;
    display: flex;
    margin-bottom: 30px;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.1);
    .image_box {
      width: 600px;
      height: 324px;
      display: flex;
      justify-content: center;
      align-items: center;
      // loading大小
      img[lazy="loading"] {
        width: 150px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        width: 100%;
        height: 100%;
      }
    }
    .spans {
      width: 600px;
      height: 324px;
      padding: 64px 48px 95px 48px;
      text-align: left;
      p {
        font-size: 32px;
        font-weight: 500;
        color: #333333;
        letter-spacing: 1px;
        margin-bottom: 24px;
      }
      span {
        font-size: 20px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
</style>
