var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner1"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('../assets/imgs/hardware_deployment/banner.webp')),expression:"require('../assets/imgs/hardware_deployment/banner.webp')"}],attrs:{"alt":""}})]),_vm._m(0)]),_c('p',{staticClass:"title_h2"},[_vm._v("景区智能硬件部署方案的优势")]),_c('span',{staticClass:"introduce_span"},[_vm._v("景区智能硬件可以提升旅游品质让游客的体验显著的提升")]),_c('div',{staticClass:"functional_area"},[_c('div',{staticClass:"chack_box ticket_exit"},[_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              require('../assets/imgs/hardware_deployment/group_up4.webp')
            ),expression:"\n              require('../assets/imgs/hardware_deployment/group_up4.webp')\n            "}]})]),_c('span',[_vm._v("\n          游客通过手机就可以预约景区门票，到了景区也不用取票，直接刷二维码就可快速完成检票，通行效率大大提升\n        ")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
            require('../assets/imgs/hardware_deployment/group_up01.png')
          ),expression:"\n            require('../assets/imgs/hardware_deployment/group_up01.png')\n          "}],staticClass:"img_1"})]),_c('div',{staticClass:"chack_box narrate"},[_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              require('../assets/imgs/hardware_deployment/group_up4.2.webp')
            ),expression:"\n              require('../assets/imgs/hardware_deployment/group_up4.2.webp')\n            "}]})]),_c('span',[_vm._v("\n          游客到了景区，只需要一部手机，就可以获取图、文、音、视频等多种形式的景区解说\n        ")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('../assets/imgs/hardware_deployment/group_up2.png')),expression:"require('../assets/imgs/hardware_deployment/group_up2.png')"}],staticClass:"img_2"})]),_c('div',{staticClass:"chack_box path"},[_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              require('../assets/imgs/hardware_deployment/group_up.webp')
            ),expression:"\n              require('../assets/imgs/hardware_deployment/group_up.webp')\n            "}]})]),_c('span',[_vm._v("\n          游客还可以根据自己的需求，获取最符合自己游玩偏好的个性化游玩路线\n        ")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('../assets/imgs/hardware_deployment/group_up1.png')),expression:"require('../assets/imgs/hardware_deployment/group_up1.png')"}],staticClass:"img_3"})]),_c('div',{staticClass:"chack_box path"},[_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              require('../assets/imgs/hardware_deployment/group_up4.1.webp')
            ),expression:"\n              require('../assets/imgs/hardware_deployment/group_up4.1.webp')\n            "}]})]),_c('span',[_vm._v("\n          通过无人机了解到游客的实时动态和需求提醒游客注意全，为游客的游玩保驾护航\n        ")]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('../assets/imgs/hardware_deployment/group_up3.png')),expression:"require('../assets/imgs/hardware_deployment/group_up3.png')"}],staticClass:"img_4"})])]),_c('div',{staticClass:"advantage"},[_c('div',{staticClass:"centers"},[_c('div',{staticClass:"images"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              require('../assets/imgs/hardware_deployment/group_up12.webp')
            ),expression:"\n              require('../assets/imgs/hardware_deployment/group_up12.webp')\n            "}]})]),_vm._m(1)])]),_c('div',{staticClass:"scene_area"},[_c('p',{staticClass:"title"},[_vm._v("景区智能硬件部署使用场景")]),_c('div',{staticClass:"verify"},[_c('div',{staticClass:"image_box"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              require('../assets/imgs/hardware_deployment/group_up5.webp')
            ),expression:"\n              require('../assets/imgs/hardware_deployment/group_up5.webp')\n            "}]})]),_vm._m(2)]),_c('div',{staticClass:"hierophant"},[_vm._m(3),_c('div',{staticClass:"image_box"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              require('../assets/imgs/hardware_deployment/group_up5.2.webp')
            ),expression:"\n              require('../assets/imgs/hardware_deployment/group_up5.2.webp')\n            "}]})])]),_c('div',{staticClass:"way"},[_c('div',{staticClass:"image_box"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              require('../assets/imgs/hardware_deployment/group_up5.1.webp')
            ),expression:"\n              require('../assets/imgs/hardware_deployment/group_up5.1.webp')\n            "}]})]),_vm._m(4)]),_c('div',{staticClass:"flay"},[_vm._m(5),_c('div',{staticClass:"image_box"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
              require('../assets/imgs/hardware_deployment/group_up5.3.webp')
            ),expression:"\n              require('../assets/imgs/hardware_deployment/group_up5.3.webp')\n            "}]})])])])]),_c('Header'),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner2"},[_c('h2',{staticClass:"title"},[_vm._v("硬件部署方案")]),_c('p',{staticClass:"introduce"},[_vm._v("实现景区智能化，为游客全方位提供优质的服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"character"},[_c('p',[_vm._v("景区智能硬件部署方案的优势")]),_c('span',{staticClass:"spn_1"},[_c('div'),_vm._v("\n            通过采集、清洗、整合、分析等一系列分析手段，形成的多维度、多类型的旅游数据\n          ")]),_c('span',{staticClass:"spn_2"},[_c('div'),_vm._v("\n            通过旅游大数据，管理者可以实时、联动、综合、全面的了解景区经营状况，并通过智慧旅游大数据中心的分析预警，预判景区未来的经营趋势，特别是在应对突发状况时，就可以在第一时间有效地启动应急方案，使得景区管理趋于科学，进一步打造智慧景区\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spans"},[_c('p',[_vm._v("智慧验证")]),_c('span',[_vm._v("\n            实地购票验票花费较多的时间和人力成本，通过智能验证身份获取游客的购票信息，可以让游客快速通过，解决高峰期游客拥堵的情况，为游客节约了游玩的时间\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spans"},[_c('p',[_vm._v("智慧解说")]),_c('span',[_vm._v("\n            游客众多的时候，景区不可能做到一人一导游的配置，通过智能解说让游客可通过手机就能了解到景区的特色和历史，提高了游客的游玩体验\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spans"},[_c('p',[_vm._v("智慧路线")]),_c('span',[_vm._v("\n            游客初次到景区会对景区不熟悉，不知道如何规划路线，通过智能路线，可根据游客选择的个性爱好为游客规划游玩路线，让游客体验到自己想要的旅游，为游客节约了考虑的时间，让游客不虚此行\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spans"},[_c('p',[_vm._v("无人机提示")]),_c('span',[_vm._v("\n            在智能化景区难免有游客不知如何操作景区设施，或者需要人工帮助的时候，可通过无人机提示让游客正确的操作景区设施，为游客提供便捷的方法，也可通过无人机了解到游客的需求，并给与游客帮助\n          ")])])
}]

export { render, staticRenderFns }